export const ALGOLIA_APP_ID =
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? 'R6YG2F94WX';
export const ALGOLIA_SEARCH_API_KEY =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ??
  'b5b0559e7ff060b8e25f5a0a5bd99fd3';
export const PROTOCOL_HOSTNAME = process.env.NEXT_PUBLIC_HOSTNAME
  ? process.env.NEXT_PUBLIC_HOSTNAME?.startsWith('http')
    ? process.env.NEXT_PUBLIC_HOSTNAME
    : `https://${process.env.NEXT_PUBLIC_HOSTNAME}`
  : 'http://localhost:3000';
export const NEXT_PUBLIC_IMGS_URL = `${PROTOCOL_HOSTNAME}/api/images/`;
export const INSTRUMENTATION_KEY =
  process.env.NEXT_PUBLIC_INSTRUMENTATION_KEY ?? '';
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? '';

// API Urls
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL ?? '';
export const APIM_SUBSCRIPTION_KEY =
  process.env.NEXT_PUBLIC_APIM_SUBSCRIPTION_KEY ?? '';
export const PAGES_API_URL = 'pages/api/';
export const PAGES_API_URL_SERVER =
  process.env.NEXT_PUBLIC_API_URL_PAGES_SERVER ?? '';
export const ENHANCED_CONTENT_API_URL = 'enhancedcontent/api/';
export const ENHANCED_CONTENT_API_URL_SERVER =
  process.env.NEXT_PUBLIC_API_URL_ENHANCED_CONTENT_SERVER ?? '';
export const SEARCH_FORMS_URL =
  process.env.NEXT_PUBLIC_API_SEARCH_FORMS_URL ?? '';
export const PROFILE_API_URL = 'profile/api/';
export const PROFILE_API_URL_SERVER =
  process.env.NEXT_PUBLIC_API_URL_PROFILE_SERVER ?? '';
export const SAMPLES_API_URL = process.env.NEXT_PUBLIC_API_SAMPLE;
export const API_URL_CHATBOT = process.env.NEXT_PUBLIC_API_URL_CHATBOT ?? '';
export const API_AI_SUBSCRIPTION_KEY = process.env.NEXT_PUBLIC_API_AI_KEY ?? '';
export const DOCUMENTS_PROFILE_API_URL =
  process.env.NEXT_PUBLIC_PROFILE_API_URL ?? '';

export const KICKBOX_API_KEY = process.env.KICKBOX_API_KEY ?? '';
export const GA_ID = 'UA-219498217-1';
export const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID ?? '';
export const HOTJAR_SV = process.env.NEXT_PUBLIC_HOTJAR_SV ?? '';
export const IMGS_URL = process.env.NEXT_PUBLIC_IMGS_URL;
export const ALGOLIA_INDEX =
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME ?? 'prod_LABELS';
export const ALGOLIA_INSURANCES_INDEX = 'dev_MMIT_PLANNAME_FORMULARYID';
export const REP_LIST_PATH = '/connect';
export const RELAY_HUBSPOT_FORM_ID =
  process.env.NEXT_PUBLIC_RELAY_HUBSPOT_FORM_ID ?? '';
export const PRESCRIBABILITY_API_URL =
  process.env.NEXT_PUBLIC_API_URL_PRESCRIBABILITY ?? '';
export const ALGOLIA_PHARMACIES_INDEX =
  process.env.NEXT_PUBLIC_ALGOLIA_PHARMACIES_INDEX ?? '';
export const TEMP_PDP_ALGOLIA_PHARMACIES_INDEX =
  process.env.NEXT_PUBLIC_TEMP_PDP_ALGOLIA_PHARMARCIES_INDEX ?? '';
export const PHARMACY_STORAGE =
  'https://pptspecialtypharmacy001.blob.core.windows.net';
export const PRESCRIBER_POINT_IMAGE_URL =
  'https://prescriber-point.s3.us-west-1.amazonaws.com/assets/favicon-360.png';
export const IS_PRODUCTION = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';
export const HUBSPOT_SIGNUP_DRUG_UPDATE_FORM_ID =
  process.env.NEXT_PUBLIC_HUBSPOT_SIGNUP_DRUG_UPDATE_FORM_ID;
export const HUBSPOT_PAYWALL_FORM_ID =
  process.env.NEXT_PUBLIC_HUBSPOT_PAYWALL_FORM_ID;
export const GOOOGLE_MAPS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '';
export const PHARMACY_COSMOS_CONTAINER_NAME =
  process.env.PHARMACY_COSMOS_CONTAINER_NAME ?? '';
export const PHARMACY_COSMOS_DB_NAME =
  process.env.PHARMACY_COSMOS_DB_NAME ?? '';
export const COSMOS_ENDPOINT = process.env.COSMOS_ENDPOINT ?? '';
export const PHARMACY_COSMOS_KEY = process.env.PHARMACY_COSMOS_KEY ?? '';
export const MCO_COSMOS_CONTAINER_NAME =
  process.env.MCO_COSMOS_CONTAINER_NAME ?? '';
export const MMIT_EMPLOYERS_COSMOS_CONTAINER_NAME =
  process.env.MMIT_EMPLOYERS_COSMOS_CONTAINER_NAME ?? '';
export const REMS_COSMOS_CONTAINER_NAME =
  process.env.REMS_COSMOS_CONTAINER_NAME ?? '';
export const REMS_COSMOS_DB_NAME = process.env.REMS_COSMOS_DB_NAME ?? '';
export const AI_CHATBOT_LINK =
  process.env.NEXT_PUBLIC_AI_CHATBOT_LINK ?? 'https://prescriberpoint.com/ai';
export const UNKNOWN_ERROR = 'E_UNKNOWN';
export const ADOBE_TAG_URL = process.env.NEXT_PUBLIC_ADOBE_TAG_URL ?? '';
export const CDN_URL = 'https://cdn.prescriberpoint.com';
export const CDN_URL_SERVER = process.env.CDN_SERVER_URL ?? '';
export const LOCAL_FILES_PATH = process.env.LOCAL_FILES_PATH ?? '/mnt';
export const CONTENTFUL_KEY = process.env.CONTENTFUL_MGMT_KEY ?? '';
export const CONTENTFUL_API_URL = process.env.CONTENTFUL_API_URL ?? '';

//AZURE STORAGE
export const AZURE_STORAGE_LABELS_ACCOUNT_NAME =
  process.env.AZURE_STORAGE_LABELS_ACCOUNT_NAME ?? '';
export const AZURE_STORAGE_LABELS_KEY =
  process.env.AZURE_STORAGE_LABELS_KEY ?? '';
export const AZURE_STORAGE_LABELS_ENDPOINT =
  process.env.NEXT_PUBLIC_AZURE_STORAGE_LABELS_ENDPOINT ?? '';
export const LABELS_STORAGE_CONTAINER_NAME = 'labels';

//MMIT
export const MMIT_COSMOS_DB_NAME = process.env.MMIT_COSMOS_DB_NAME ?? '';
export const MMIT_API_URL = process.env.MMIT_API_URL ?? '';
export const MMIT_API_PASSWORD = process.env.MMIT_API_PASSWORD ?? '';
export const MMIT_API_USERNAME = process.env.MMIT_API_USERNAME ?? '';
export const MMIT_API_EMGALITY_USERNAME =
  process.env.MMIT_API_EMGALITY_USERNAME ?? '';
export const MMIT_API_EMGALITY_PASSWORD =
  process.env.MMIT_API_EMGALITY_PASSWORD ?? '';
export const INDICATIONS_COSMOS_CONTAINER_NAME =
  process.env.MMIT_INDICATIONS_COSMOS_CONTAINER_NAME ?? '';
export const AFFORDABILITY_COSMOS_CONTAINER_NAME =
  process.env.MMIT_AFFORDABILITY_COSMOS_CONTAINER_NAME ?? '';

// Redis
export const AZURE_CACHE_FOR_REDIS_CONN_STRING =
  process.env.AZURE_CACHE_FOR_REDIS_CONN_STRING ?? '';
export const AZURE_CACHE_FOR_REDIS_PASSWORD =
  process.env.AZURE_CACHE_FOR_REDIS_PASSWORD ?? '';
export const AZURE_CACHE_FOR_REDIS_PORT = 6380;

// Labels
export const LABELS_COSMOS_DB_NAME = process.env.LABELS_COSMOS_DB_NAME ?? '';
export const LABELS_COSMOS_CONTAINER_NAME =
  process.env.LABELS_COSMOS_CONTAINER_NAME ?? '';
export const FEATURE_FLAGS_ENVIRONMENT =
  process.env.NEXT_PUBLIC_FEATURE_FLAGS_ENVIRONMENT ?? '';

// Med ai drugs
export const MED_AI_SET_IDS = [
  '8bc6397e-4bd8-4d37-a007-a327e4da34d9',
  'c6080942-dee6-423e-b688-1272c2ae90d4',
];

export const MED_AI_API_URL =
  process.env.NEXT_PUBLIC_MED_AI_CHATBOT_API_URL ?? '';
