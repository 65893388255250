import {Text, customEvent} from '@prescriberpoint/ui';
import {BotMessageSquareIcon, ChevronUpIcon} from 'lucide-react';
import {FC} from 'react';
import MedAIMobileCta from './MedAIMobileCta';
import {CEVENT_BUTTON_CLICKED} from '@/constants';
import * as FLAGS from '@/constants/flags';
import {useFlag} from '@/context';
import {useCurrentSlug, useEnhanced} from '@/hooks';
import {useIsPfizerDrug} from '@/hooks/useIsPfizerDrug';
import {useShowMedAi} from '@/hooks/useShowMedAi';


const PrescriberAIMobile: FC = () => {
  const {setId} = useCurrentSlug();
  const {isEnhanced, disableAi} = useEnhanced(setId);
  const showAIPPT = useFlag(FLAGS.PPT_AI_COMPONENT) && !disableAi;
  const hideChatbotForNoEnhanced = useFlag(FLAGS.HIDE_CHATBOT_FOR_NO_ENHANCED);

  const isPfizerDrug = useIsPfizerDrug();
  const showButtonAI =
    !isPfizerDrug && showAIPPT && (isEnhanced || !hideChatbotForNoEnhanced);
  const showMedAi = useShowMedAi(setId);

  if (!showMedAi && !showButtonAI) {
    return;
  }

  const onHandleClick = () => {
    customEvent(CEVENT_BUTTON_CLICKED, {id: 'aiWC_button'});
  };

  return showMedAi ? (
    <MedAIMobileCta />
  ) : (
    <div className='static animate-fadeIn'>
      <div className='fixed bottom-0 left-0 z-20 w-full'>
        <button
          id='aiWC_button'
          onClick={onHandleClick}
          className='flex w-full cursor-pointer items-center gap-x-2 rounded-t-[20px] bg-neutral-primary px-5 py-4 text-left'>
          <BotMessageSquareIcon className='text-neutral-lighter' size={20} />
          <Text
            as='body-sm'
            weight='bold'
            className='flex-1 !text-neutral-lighter'>
            Prescriber AI
          </Text>
          <ChevronUpIcon size={24} className='text-neutral-lighter' />
        </button>
      </div>
    </div>
  );
};

export default PrescriberAIMobile;
